import { Checkbox, Space, Typography } from "antd";
import { utils } from "../../services/utils";

const LottoCheckView = ({ isLoading, lottoMaster, isCheckedAll, handleCheckAll, handleCheckboxChange, role, permissions, type, name }: any) => {
    const renderLottoMaster = () => {
        if (isLoading) return <></>;

        if (role === 'header') {
            if (permissions !== 'null') {
                const lottoMasterPermissions = utils.getLottoPermissions(true);
                const lotto = lottoMasterPermissions.map((v: any) => {
                    const option = {
                        label: v.name,
                        value: v.id
                    }
                    return option;
                });

                return lotto;
            }
        }

        return lottoMaster.map((lotto: any) => {
            if (lotto.lottoType?.id === type) {
                return (
                    <Checkbox
                        key={lotto.id}
                        checked={lotto.selected}
                        onChange={() => handleCheckboxChange(lotto.id, type)}
                    >
                        {lotto.name}
                    </Checkbox>
                );
            }
        })
    }


    return (
        <>
            <Typography.Title level={5}>{name}</Typography.Title>
            <Checkbox style={{ marginLeft: 8 }} checked={isCheckedAll} onChange={(e) => handleCheckAll(e, type)}>
                เลือกทั้งหมด |
            </Checkbox>
            {renderLottoMaster()}
        </>
    )
}

export default LottoCheckView;