import { CloseOutlined, ReloadOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Descriptions, Empty, Modal, Space, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import { getBillsToday, logout } from "../../services/services";
import { BetType } from "../../types/bet.type";
import { BillType } from "../../types/bill.type";
import './betReport.css';

const { Title, Text } = Typography;

const setBillColumns = (onShowBetsInBill: Function) => {
    const columns: ColumnsType<BillType> = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text: string, record: BillType, index: number) => {
                return (
                    <>
                        {record.status === 'inactive' ? <><CloseOutlined style={{ color: '#ff4d4f' }} /> {index + 1}</> : index + 1}
                    </>
                );
            }
        },
        {
            title: 'ผู้ขาย',
            dataIndex: 'member',
            key: 'member',
            align: 'center',
            render: (text: string, record: BillType, index: number) => {
                return record.member.nickname;
            }
        },
        {
            title: 'งวดวันที่',
            dataIndex: 'period',
            key: 'period',
            render: (text: string, record: BillType, index: number) => {
                return moment(record.period.dateAnnounce).format("วันที่ DD-MM-YYYY");
            }
        },
        {
            title: 'เวลาแทง',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string, record: BillType, index: number) => {
                return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
            }
        },
        {
            title: 'ตลาด',
            dataIndex: 'market',
            key: 'market',
            render: (text: string, record: BillType, index: number) => {
                return (<><img src={process.env.REACT_APP_API_URL + record.period.lottoMaster.pic} width={40} height={30} /> {record.period.lottoMaster.name}</> )
            }
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'detail',
            key: 'detail',
            align: 'center',
            render: (text: string, record: BillType, index: number) => {
                return text ? text : '-'
            }
        },
        {
            title: 'ยอดเงิน',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (text: string, record: BillType, index: number) => {
                return <span style={{ color: '#389e0d' }}>{text}</span>
            }
        },
        {
            title: 'การแทง',
            dataIndex: 'betType',
            key: 'betType',
            align: 'center',
            render: (text: string, record: BillType, index: number) => {
                return (
                    <>
                        <Space size="small" align="center">
                            <Button size="small" type="primary" onClick={() => onShowBetsInBill(record)}><SnippetsOutlined /></Button>
                        </Space>
                    </>
                )
            }
        },
    ];
    return columns;
}

const betColumns: ColumnsType<any> = [
    {
        title: 'ลำดับที่',
        dataIndex: 'id',
        key: 'id',
        render: (text: string, record: BetType, index: number) => {
            return index + 1;
        }
    },
    // {
    //     title: 'หมายเลข',
    //     dataIndex: 'numbers',
    //     key: 'numbers',
    // },
    // {
    //     title: 'ประเภท',
    //     dataIndex: 'betDataMaster.name',
    //     key: 'betDataMaster.name',
    //     render: (text: string, record: BetType, index: number) => {
    //         return record.betDataMaster.name;
    //     }
    // }
    {
        title: 'ประเภท @ หมายเลข',
        dataIndex: 'numbersBetDataMaster',
        key: 'numbersBetDataMaster',
        align: 'center',
        render: (text: string, record: BetType, index: number) => {
            return record.betDataMaster.name + " @ " + record.numbers;
        }
    },
    {
        title: 'ราคา',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        render: (text: string, record: BetType, index: number) => {
            return <span style={{ color: '#389e0d' }}>{text}</span>
        }
    },
];

const BetReport: FC = () => {
    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [isShowBill, setIsShowBill] = useState(false);
    const [currentBetsInBill, setCurrentBetsInBill] = useState<any>([]);
    const [currentBill, setCurrentBill] = useState<BillType>();
    const [allBill, setAllBill] = useState(null);

    const getAllBillsToDay = useCallback(async (memberId: string | null) => {
        const response = await getBillsToday(memberId);
        setAllBill(response.data);
    }, []);

    useEffect(() => {
        initData();
    }, [history, getAllBillsToDay]);

    const initData = () => {
        setLoading(true);
        getAllBillsToDay(localStorage.getItem('memberId')).then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }

    const rowSetup = (record: BillType, index: number) => {
        if (record.status === 'inactive') {
            return 'inactive-bill';
        }
        return '';
    }

    const onShowBetsInBill = (bill: BillType) => {
        setIsShowBill(true);
        setCurrentBill(bill);
        setCurrentBetsInBill(bill.bets);
    }



    const billInCurrentPeriod = () => {
        if (!allBill) return [];

        const data: BillType[] = allBill['data'];
        return data;
    }

    return (
        <Spin spinning={loading}>
            <MainLayout menu="bet-report">
                <Title level={4}>รายการแทง</Title>
                <Button type="primary" style={{ marginBottom: 10 }} onClick={() => initData()}><ReloadOutlined /> รีเฟรช</Button>
                <Table pagination={false} rowClassName={rowSetup} columns={setBillColumns(onShowBetsInBill)} dataSource={billInCurrentPeriod()} size="small" locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}></Table>

                <Modal title="ข้อมูลโพย"
                    open={isShowBill}
                    onOk={() => setIsShowBill(false)}
                    onCancel={() => setIsShowBill(false)}
                    okText="ปิด"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Descriptions>
                        <Descriptions.Item label="หมายเหตุ">{currentBill?.detail}</Descriptions.Item>
                        <Descriptions.Item label="ยอดเงิน">{currentBill?.price} บาท</Descriptions.Item>
                    </Descriptions>
                    <Table pagination={false} columns={betColumns} dataSource={[...currentBetsInBill]} size="small" locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}></Table>
                </Modal>
            </MainLayout>
        </Spin>
    )
}

export default BetReport;