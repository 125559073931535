import { Button, Checkbox, DatePicker, Descriptions, Divider, Empty, Modal, Space, Spin, Table, Tag, Typography } from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import Notification from "../../components/notification/notification";
import { getLottoMaster, getMemberResult, getResultByDay, logout } from "../../services/services";
import { utils } from "../../services/utils";
import { BetType } from "../../types/bet.type";
import { LottoMaster, PeriodType } from "../../types/period.type";
import LottoCheckView from "../../components/lotto-check/lotto-check-view";


const { Title } = Typography;
const { RangePicker } = DatePicker;

const renderPeriodColumns = (onShowPeriodDetail: Function) => {
    const columns: ColumnsType<any> = [
        {
            title: <b>งวดที่</b>,
            dataIndex: 'dateAnnounce',
            key: 'dateAnnounce',
            width: 500
        },
        {
            title: <b>ยอดซื้อ</b>,
            dataIndex: 'buy',
            key: 'buy',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: "green" }}>{text}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#7cb305' }}>{utils.numberWithCommas(text)}</span>
            }
        },
        {
            title: <b>ยอดจ่าย</b>,
            dataIndex: 'pay',
            key: 'pay',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#d4380d' }}>{text}</span>
            }
        },
        {
            title: <b>คงเหลือ</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                const sum = parseFloat(record.buySummary) - (parseFloat(record.paySummary) + parseFloat(record.commission));
                return <span style={{ fontSize: 16, color: '#c41d7f' }}>{utils.numberWithCommas(sum + "")}</span>
            }
        },
        {
            title: <b>รายการแทง</b>,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return <Button type="link" onClick={() => onShowPeriodDetail(record)}>รายการแทง</Button>
            }
        },
    ]

    return columns;
}

const renderBillColumns = () => {
    const columns: ColumnsType<any> = [
        // {
        //     title: 'หมายเลข',
        //     dataIndex: 'numbers',
        //     key: 'numbers'
        // },
        // {
        //     title: 'การแทง',
        //     dataIndex: 'betDataMaster',
        //     key: 'betDataMaster'
        // },
        {
            title: <b>ประเภท @ หมายเลข</b>,
            dataIndex: 'numbersBetDataMaster',
            key: 'numbersBetDataMaster',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return record.betDataMaster + " @ " + record.numbers;
            }
        },
        {
            title: <b>ราคาแทง</b>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                return <span style={{ color: '#389e0d' }}>{text}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                return <span style={{ color: '#cf1322' }}>{text}</span>
            }
        },
        {
            title: 'สถานะ',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return record.status === 'lose' ? <span style={{ color: 'red' }}>แพ้</span> : <span style={{ color: 'green' }}>ชนะ = {record.earn}</span>;
            }
        },
        // {
        //     title: 'จ่าย',
        //     dataIndex: 'earn',
        //     key: 'earn',
        //     render: (text: string, record: any, index: number) => {  
        //         return record.status === 'lose' ? <span style={{ color: 'red' }}>{record.earn}</span> : <span style={{ color: 'green' }}>{record.earn}</span>;
        //     }
        // },
        {
            title: <b>หมายเหตุ</b>,
            dataIndex: 'detail',
            key: 'detail',
            align: 'right',
        },
        {
            title: <b>คนขาย</b>,
            dataIndex: 'member',
            key: 'member',
            align: 'center',
        },
    ];

    return columns;
}

const renderMemberResultColumns = (onShowMemberPeriodDetail: Function) => {
    const columns: ColumnsType<any> = [
        {
            title: <b>ลำดับ</b>,
            dataIndex: 'id',
            key: 'id',
            width: 100,
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return index + 1
            }
        },
        {
            title: <b>สมาชิก</b>,
            dataIndex: 'nickname',
            key: 'nickname',
            width: 400
        },
        {
            title: <b>ยอดทั้งหมด</b>,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: 'green' }}>{utils.numberWithCommas(text)}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#7cb305' }}>{utils.numberWithCommas(text)}</span>
            }
        },
        {
            title: <b>ยอดจ่าย</b>,
            dataIndex: 'pay',
            key: 'pay',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                return <span style={{ fontSize: 16, color: '#d4380d' }}>{utils.numberWithCommas(text)}</span>
            }
        },
        {
            title: <b>คงเหลือ</b>,
            dataIndex: 'net',
            key: 'net',
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <span style={{ fontSize: 16 }} color="cyan">{text}</span>
                const sum = parseFloat(record.total) - (parseFloat(record.pay) + parseFloat(record.commission));
                return <span style={{ fontSize: 16, color: '#c41d7f' }}>{utils.numberWithCommas(sum + "")}</span>
            }
        },
        {
            title: <b>รายการแทง</b>,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return <Button type="link" onClick={() => onShowMemberPeriodDetail(record)}>รายการแทง</Button>
            }
        },
    ];

    return columns;
}

const Result: FC = () => {
    const history = useHistory();
    const [date, setDate] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [dataSummary, setDataSummary] = useState<any>(null);
    const [summary, setSummary] = useState<any>(null);
    const [curentPeriod, setCurentPeriod] = useState<any>(null);
    const [memberResult, setMemberResult] = useState<any>([]);
    const [lottoMaster, setLottoMaster] = useState<any>([]);
    // const [lottoMasterChecked, setLottoMasterChecked] = useState<CheckboxValueType[]>([]);
    const { role, permissions } = utils.getRoleAndPermissions();
    const [isCheckedAllType1, setIsCheckedAllType1] = useState(false);
    const [isCheckedAllType2, setIsCheckedAllType2] = useState(false);
    const [isCheckedAllType3, setIsCheckedAllType3] = useState(false);
    const [buttonTypes, setButtonTypes] = useState<any>(["default", "default", "default", "default", "default", "default"]);

    const getAllLottoMaster = useCallback(async () => {
        const response = await getLottoMaster();

        setLottoMaster(response.data.data.map((lotto: any) => {
            return {
                ...lotto,
                selected: false
            }
        }));
    }, []);

    useEffect(() => {
        setLoading(true);
        getAllLottoMaster().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }, [getAllLottoMaster]);

    const renderDataSummary = (dataSummary: any) => {
        if (!dataSummary) {
            return [];
        }

        if (dataSummary.length === 0) {
            return [];
        }

        const data = dataSummary.map((period: PeriodType, index: number) => {
            const createdDate = moment(period.createdAt).add(543, 'year').toDate();
            const periodDate = moment(createdDate).format('วันที่ DD MMMM YYYY');
            const announceDate = moment(moment(period.dateAnnounce).toDate()).format('เวลา HH:mm น.');

            let bets: any = [];
            let total = 0.00;
            let pay = 0.00;
            let commission = 0.00;
            period.bills?.forEach(bill => {
                // total += bill.price;
                pay += bill.pay;
                bill.bets.map((v: BetType) => {
                    v.detail = bill.detail;
                    v.member = bill.member.nickname;
                    v.commission = (v.betDataMaster.commissionRateMaster * 1.00) * (v.price / 100.00);
                    commission += v.commission;
                    total += v.price;
                    return v;
                });
                bets.push(...bill.bets);
            });

            return {
                dateAnnounce: "หวย" + period.lottoMaster?.name + " " + periodDate + " (" + announceDate + ")",
                buy: utils.numberWithCommas(total + ""),
                buySummary: total,
                pay: utils.numberWithCommas(pay + ""),
                paySummary: pay,
                period,
                bets,
                commission
            };
        });

        return data;
    }

    const totalSummary = () => {

        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.forEach((v: any) => {
            sum += v.buySummary;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const commissionSummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.forEach((v: any) => {
            sum += v.commission;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const paySummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.forEach((v: any) => {
            sum += v.paySummary;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const netSummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let total = 0.00;
        let pay = 0.00;
        let commission = 0.00;
        summary.forEach((v: any) => {
            total += v.buySummary;
            pay += v.paySummary;
            commission += v.commission;
        });

        let sum = total - (pay + commission);
        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const renderBet = () => {
        if (!curentPeriod) {
            return null;
        }

        return curentPeriod.bets.map((bet: any, index: number) => {
            return {
                numbers: bet.numbers,
                betDataMaster: bet.betDataMaster.name,
                price: bet.price,
                status: bet.status,
                earn: bet.earn,
                commission: bet.commission,
                detail: bet.detail ? bet.detail : '-',
                member: bet.member
            }
        });
    }

    const getLottoMasterChecked = () => {
        const lottoMasterChecked = lottoMaster.filter((lotto: any) => {
            if (lotto.selected) return lotto;
        });

        return lottoMasterChecked;
    }

    const search = async (startDate: string, endDate: string) => {
        const lottoMasterChecked = getLottoMasterChecked();
        if (lottoMasterChecked.length === 0) {
            return Notification('error', 'กรุณาเลือกตลาด');
        }

        const lottoMasterCheckedIds = lottoMasterChecked.map((lotto: any) => lotto.id);
        setLoading(true);
        // moment(date[0]).format('YYYY-MM-DD 00:00:00');
        // moment(date[1]).format('YYYY-MM-DD 23:59:59')
        try {
            let filter = 'filter=dateOpen||$gte||' + startDate;
            filter += '&filter=dateOpen||$lte||' + endDate;
            filter += '&filter=bills.status||$eq||active';
            if (lottoMasterCheckedIds.length > 0) {
                filter += '&filter=lottoMaster.id||$in||' + lottoMasterCheckedIds.join(",");
            } else {
                if (role === 'header') {
                    if (permissions !== 'null') {
                        const lottoMasterPermissions = utils.getLottoPermissions(true);
                        const data = lottoMasterPermissions.map((v: LottoMaster) => v.id);
                        filter += '&filter=lottoMaster.id||$in||' + data.join(",");
                    }
                }
            }
            // filter += '&join=bill&join=bill.bet'
            // let filter = 'filter=periods.dateOpen||$eq||' + date;
            const response = await getResultByDay(filter);

            // filter data
            const summary = renderDataSummary(response.data.data);
            setSummary(summary);
            setDataSummary(response.data.data);

            // get member summary
            await searchMemberResult('between', startDate, endDate);
        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาดเล็กน้อย');
        }

        setLoading(false);
    }

    const searchByTime = async (status: string, type: number = 0) => {
        const lottoMasterChecked = getLottoMasterChecked();
        if (lottoMasterChecked.length === 0) {
            return Notification('error', 'กรุณาเลือกตลาด');
        }

        let dateByStatus = {
            startDate: null || '',
            endDate: null || ''
        }

        if (status === 'today') {
            dateByStatus.startDate = moment().format('YYYY-MM-DD 00:00:00');
            dateByStatus.endDate = moment().format('YYYY-MM-DD 23:59:59');
        }

        if (status === 'yesterday') {
            dateByStatus.startDate = moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00');
            dateByStatus.endDate = moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59');
        }

        if (status === 'between') {
            if (!date) return Notification('error', 'กรุณาเลือกวันที่');

            dateByStatus.startDate = moment(date[0]).format('YYYY-MM-DD 00:00:00');
            dateByStatus.endDate = moment(date[1]).format('YYYY-MM-DD 23:59:59');
        }

        if (status === 'current-week' || status === 'last-week') {
            const date = utils.getWeek(status);
            dateByStatus.startDate = date.startDate;
            dateByStatus.endDate = date.endDate;
        }

        if (status === 'current-month' || status === 'last-month') {
            const date = utils.getMonth(status);
            dateByStatus.startDate = date.startDate;
            dateByStatus.endDate = date.endDate;
        }

        const transparented = ["default", "default", "default", "default", "default", "default"];
        transparented[type] = "primary";
        setButtonTypes(transparented);
        setDate([dateByStatus.startDate, dateByStatus.endDate]);

        await search(dateByStatus.startDate, dateByStatus.endDate);
    }

    const searchMemberResult = async (type: string, startDate: string, endDate: string) => {
        // moment(date[0]).format('YYYY-MM-DD 00:00:00')
        // moment(date[1]).format('YYYY-MM-DD 23:59:59')
        const lottoMasterChecked = getLottoMasterChecked();
        const lottoMasterCheckedIds = lottoMasterChecked.map((lotto: any) => lotto.id);
        try {
            let filter = '?startDate=' + startDate;
            filter += '&endDate=' + endDate;
            filter += '&type=' + type;
            if (lottoMasterCheckedIds.length > 0) {
                filter += '&lottoMaster=' + lottoMasterCheckedIds.join(",");
            } else {
                if (role === 'header') {
                    if (permissions !== 'null') {
                        const lottoMasterPermissions = utils.getLottoPermissions(true);
                        const data = lottoMasterPermissions.map((v: LottoMaster) => v.id);
                        filter += '&filter=lottoMaster||$in||' + data.join(",");
                    }
                }
            }
            const response = await getMemberResult(filter);
            setMemberResult(response.data);
        } catch (error) {
            Notification('error', 'แจ้งเตือน', 'เกิดข้อผิดพลาดเล็กน้อย');
        }
    }

    const onShowPeriodDetail = (period: any) => {
        return history.push({
            pathname: '/result/bill',
            state: {
                period
            }
        })
    }

    const renderFooterSummary = (dataSummary: any) => {

        if (dataSummary.length === 0) return <></>;

        // console.log(dataSummary);
        let price = 0;
        let commission = 0;
        let win = 0;
        let net = 0;

        dataSummary.forEach((v: any) => {
            price += v.buySummary;
            const commissionCal = v.commission
            commission += commissionCal;
            win += v.paySummary;

            net += v.buySummary - (v.commission + v.paySummary);
        });

        // const net = (win + commission) + price;

        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} align="center">
                        <span style={{ fontWeight: 'bold' }}>รวม</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#389e0d' }}>{utils.numberWithCommas(price + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#7cb305' }}>{utils.numberWithCommas(commission + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#d4380d' }}>{utils.numberWithCommas(win + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#c41d7f' }}>{utils.numberWithCommas(net + "")}</span>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        )
    }

    const goReport = (location: any) => {
        const lottoMasterChecked = getLottoMasterChecked();
        const lottoMasterCheckedIds = lottoMasterChecked.map((lotto: any) => lotto.id);

        const total = totalSummary();
        const commission = commissionSummary();
        const pay = paySummary();
        let query = "startDate=" + (!date ? '' : moment(date[0]).format('YYYY-MM-DD 07:00:00'));
        query += "&endDate=" + (!date ? '' : moment(date[1]).format('YYYY-MM-DD 07:00:00'));
        query += "&total=" + total;
        query += "&commission=" + commission;
        query += "&pay=" + pay;

        if (lottoMasterCheckedIds.length > 0) query += "&lottoMaster=" + lottoMasterCheckedIds.join(",");
        return 'report?' + query;
    }

    const onShowMemberPeriodDetail = (data: any) => {
        const dataSummaryTemp = [...dataSummary];

        const memberBills: any = [];
        dataSummaryTemp.forEach((v: any) => {
            const period = { ...v };
            delete period.bills;

            let billsFilter = v.bills.filter((bill: any) => bill.member.id === data.id);
            billsFilter.forEach((bill: any) => {
                memberBills.push({
                    ...bill,
                    period
                })
            });
        });

        return history.push({
            pathname: '/result/bill-member',
            state: {
                memberBills,
                member: data
            }
        })
    }

    const handleCheckAll = (e: any, type: number) => {

        if (e.target.checked) {
            const updatedCheckboxes = lottoMaster.map((lotto: any) => {
                if (lotto.lottoType.id === type && !lotto.selected) {
                    lotto.selected = true;
                    return lotto; // Check only unchecked boxes
                }
                return lotto; // Keep checked boxes as is
            });

            setLottoMaster(updatedCheckboxes);
            if (type === 1) setIsCheckedAllType1(e.target.checked);
            if (type === 2) setIsCheckedAllType2(e.target.checked);
            if (type === 3) setIsCheckedAllType3(e.target.checked);
        } else {
            const updatedCheckboxes = lottoMaster.map((lotto: any) => {
                if (lotto.lottoType.id === type) {
                    lotto.selected = false
                }
                return lotto;
            }); // Toggle all checkboxes

            setLottoMaster(updatedCheckboxes);
            if (type === 1) setIsCheckedAllType1(e.target.checked);
            if (type === 2) setIsCheckedAllType2(e.target.checked);
            if (type === 3) setIsCheckedAllType3(e.target.checked);
        }
    };

    const handleCheckboxChange = (id: any, type: number) => {
        const updatedOptions = lottoMaster.map((lotto: any) => {
            if (lotto.lottoType.id === type && lotto.id === id) {
                return { ...lotto, selected: !lotto.selected };
            }
            return lotto;
        });
        setLottoMaster(updatedOptions);

        const lottoByType = updatedOptions.filter((lotto: any) => lotto.lottoType.id === type);
        const allChecked = lottoByType.every((lotto: any) => lotto.selected);

        if (type === 1) setIsCheckedAllType1(allChecked);
        if (type === 2) setIsCheckedAllType2(allChecked);
        if (type === 3) setIsCheckedAllType3(allChecked);
        // setIsCheckedAll(allChecked);
    };

    return (
        <MainLayout menu="result">
            <Spin spinning={loading}>
                <Title level={4}>สรุปผล</Title>
                <Space>
                    <RangePicker onChange={(e: any) => setDate(e)} placeholder={["จากวันที่", "ถึงวันที่"]} />
                    <Button type="primary" disabled={!date ? true : false} onClick={() => search(date ? moment(date[0]).format('YYYY-MM-DD 00:00:00') : '', date ? moment(date[1]).format('YYYY-MM-DD 23:59:59') : '')}>ค้นหา</Button>
                    <Button type={buttonTypes[0]} onClick={() => searchByTime('today', 0)}>วันนี้</Button>
                    <Button type={buttonTypes[1]} onClick={() => searchByTime('yesterday', 1)}>เมื่อวานนี้</Button>
                    <Button type={buttonTypes[2]} onClick={() => searchByTime('current-week', 2)}>สัปดาห์นี้</Button>
                    <Button type={buttonTypes[3]} onClick={() => searchByTime('last-week', 3)}>สัปดาห์ที่แล้ว</Button>
                    <Button type={buttonTypes[4]} onClick={() => searchByTime('current-month', 4)}>เดือนนี้</Button>
                    <Button type={buttonTypes[5]} onClick={() => searchByTime('last-month', 5)}>เดือนที่แล้ว</Button>
                </Space>
                <Divider className="divider-inside" />
                <Title level={4}>เลือกตลาด</Title>
                <LottoCheckView isLoading={loading} lottoMaster={lottoMaster} type={1} name={"หวยรายวัน"} isCheckedAll={isCheckedAllType1} handleCheckAll={handleCheckAll} handleCheckboxChange={handleCheckboxChange} />
                <Divider className="divider-inside" />
                <LottoCheckView isLoading={loading} lottoMaster={lottoMaster} type={2} name={"หวยทั่วไป"} isCheckedAll={isCheckedAllType2} handleCheckAll={handleCheckAll} handleCheckboxChange={handleCheckboxChange} />
                <Divider className="divider-inside" />
                <LottoCheckView isLoading={loading} lottoMaster={lottoMaster} type={3} name={"หวยหุ้น"} isCheckedAll={isCheckedAllType3} handleCheckAll={handleCheckAll} handleCheckboxChange={handleCheckboxChange} />
                <Divider className="divider-inside" />
                <Descriptions column={3}>
                    <Descriptions.Item label={<Typography.Text strong>จากวันที่</Typography.Text>}>{date ? moment(date[0]).add(543, 'year').format('DD MMMM YYYY') : "-"}</Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>ถึงวันที่</Typography.Text>}>{date ? moment(date[1]).add(543, 'year').format('DD MMMM YYYY') : "-"}</Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>ดูแบบละเอียด</Typography.Text>}>
                        <Link to={(location) => goReport(location)}>คลิกที่นี่</Link>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={4}>
                    <Descriptions.Item label={<Typography.Text strong>ยอดเงินรวม</Typography.Text>}><Tag color={'green'}>{totalSummary()}</Tag></Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>คอมมิชชั่นรวม</Typography.Text>}><Tag color={'lime'}>{commissionSummary()}</Tag></Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>ยอดจ่ายรวม</Typography.Text>}><Tag color={'red'}>{paySummary()}</Tag></Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>คงเหลือ</Typography.Text>}><Tag color={'magenta'}>{netSummary()}</Tag></Descriptions.Item>
                </Descriptions>
                <Divider className="divider-inside" />
                <Title level={4}>สรุปยอดแบบรายงวด</Title>
                <Table size="small" columns={renderPeriodColumns(onShowPeriodDetail)} dataSource={summary} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={false} summary={(dataSummary: any) => renderFooterSummary(dataSummary)} />
                <Divider className="divider-inside" />
                <Title level={4}>สรุปยอดแบบรายสมาชิก</Title>
                <Table size="small" columns={renderMemberResultColumns(onShowMemberPeriodDetail)} dataSource={memberResult} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={false} />
                <Divider className="divider-inside" />
                <Modal
                    title={<Title level={5}>ข้อมูลการแทง</Title>}
                    style={{ top: 25 }}
                    open={showDetailModal}
                    okText="ปิด"
                    onOk={() => setShowDetailModal(false)}
                    onCancel={() => setShowDetailModal(false)}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    width={1000}
                >
                    <Table size="small" columns={renderBillColumns()} dataSource={renderBet()} locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }} pagination={false} />
                </Modal>
            </Spin>
        </MainLayout>
    );
}

export default Result;