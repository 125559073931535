import { Button, Col, Descriptions, Divider, InputNumber, InputNumberProps, PageHeader, Popconfirm, Row, Slider, Space, Spin, Switch, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import { getLottoMaster, logout, updateLottoMaster } from "../../services/services";
import Notification from "../../components/notification/notification";
import { utils } from "../../services/utils";
import { LottoMaster } from "../../types/period.type";
import { SaveOutlined, SyncOutlined } from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

// const formatter = (value: number | undefined) => {
//     return `${value}%`;
// }

const Setting = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [lottoMaster, setLottoMaster] = useState<LottoMaster[] | []>([]);
    const { role, permissions } = utils.getRoleAndPermissions();

    const getAllLottoMaster = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMaster(response.data.data);
    }, []);

    useEffect(() => {
        setLoading(true);
        getAllLottoMaster().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }, [getAllLottoMaster]);

    const onChangeAuto = async (e: boolean, v: any) => {

        const index = lottoMaster.findIndex((i) => i.id === v.id);
        lottoMaster[index].isAuto = e ? 'active' : 'inactive';
        lottoMaster[index].autoPercent = e ? lottoMaster[index].autoPercent : 0;
        setLottoMaster([...lottoMaster]);

    }

    const onSave = async (v: any) => {
        setLoading(true);
        try {
            const body = {
                autoPercent: v.autoPercent,
                isAuto: v.isAuto
            };

            const response = await updateLottoMaster(v.id, body);
            if (response.status === 200) {
                Notification('success', 'บันทึกข้อมูลเรียบร้อย');
                getAllLottoMaster().then(() => setLoading(false)).catch((v) => {
                    setLoading(false);
                    logout();
                    return history.push('/login');
                });
            } else {
                throw new Error('กรุณาลองใหม่อีกครั้ง');
            }

        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาด ' + error);
        }

        setLoading(false);
    }

    const setLottoMasterByPermissions = () => {

        if (role === 'header') {
            if (permissions !== 'null') {
                const lottoMasterPermissions = utils.getLottoPermissions(true);
                const lottoMap = lottoMasterPermissions.map((v: LottoMaster) => v.id);

                let lottoIntersection = lottoMaster.filter(v => lottoMap.includes(v.id));

                return lottoIntersection.map((v: LottoMaster) => {
                    return (
                        <div>
                            <Row>
                                <Col md={24} sm={24} xs={24}>
                                    <Descriptions>
                                        <Descriptions.Item style={{ verticalAlign: 'middle' }}>
                                            <Text strong type="secondary" key={v.id}>{v.name}</Text>
                                        </Descriptions.Item>
                                        <Descriptions.Item style={{ verticalAlign: 'middle' }}>
                                            <Switch checkedChildren="เปิดออโต้" unCheckedChildren="ปิดออโต้" key={v["id"]} defaultChecked={v.isAuto === 'active' ? true : false} onChange={(e) => onChangeAuto(e, v)} />
                                        </Descriptions.Item>
                                        <Descriptions.Item>
                                            <IntegerStep key={v["id"]} onChange={(e: any) => onSliderChange(e, v)} defaultValue={v.autoPercent} disabled={v.isAuto !== 'active'} />
                                        </Descriptions.Item>
                                        <Descriptions.Item style={{ verticalAlign: 'middle' }}>
                                            <Popconfirm
                                                placement="topLeft"
                                                title="บันทึกการตั้งค่า"
                                                okText="บันทึก"
                                                onConfirm={() => onSave(v)}
                                                cancelText="ยกเลิก"
                                            >
                                                <Button key={v.id} type="primary" style={{ marginBottom: 10 }}><SaveOutlined /> บันทึก</Button>
                                            </Popconfirm>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Divider style={{ margin: '5px 0' }} />
                        </div>
                    )
                });
            }
        }

        return lottoMaster.map((v: any) => {
            return (
                <div>
                    <Row>
                        <Col md={24} sm={24} xs={24}>
                            <Descriptions>
                                <Descriptions.Item style={{ verticalAlign: 'middle' }}>
                                    <Text style={{ fontSize: 16 }} strong key={v.id}>{v.name}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item style={{ verticalAlign: 'middle' }}>
                                    <Switch checkedChildren="เปิดออโต้" unCheckedChildren="ปิดออโต้" key={v["id"]} defaultChecked={v.isAuto === 'active' ? true : false} onChange={(e) => onChangeAuto(e, v)} />
                                </Descriptions.Item>
                                <Descriptions.Item>
                                    <IntegerStep key={v["id"]} onChange={(e: any) => onSliderChange(e, v)} defaultValue={v.autoPercent} disabled={v.isAuto !== 'active'} />
                                </Descriptions.Item>
                                <Descriptions.Item style={{ verticalAlign: 'middle' }}>
                                    <Popconfirm
                                        placement="topLeft"
                                        title="บันทึกการตั้งค่า"
                                        okText="บันทึก"
                                        onConfirm={() => onSave(v)}
                                        cancelText="ยกเลิก"
                                    >
                                        <Button key={v.id} type="primary" style={{ marginBottom: 10 }}><SaveOutlined /> บันทึก</Button>
                                    </Popconfirm>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Divider style={{ margin: '5px 0' }} />
                </div>
            )
        })
    }

    const onSliderChange = (e: any, v: any) => {
        const index = lottoMaster.findIndex((i) => i.id === v.id);
        lottoMaster[index].autoPercent = e;
        setLottoMaster([...lottoMaster]);
    };

    const onRefresh = () => {
        setLoading(true);
        getAllLottoMaster().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }

    return (
        <MainLayout menu="setting">
            <Spin spinning={loading}>
                <PageHeader
                    title="ตั้งค่าออกผลอัตโนมัติ"
                    subTitle={<span> ออกผลอัตโนมัติโดยการจ่ายน้อยที่สุดคือ 0 บาท</span>}
                />
                <Space direction="vertical" style={{ padding: '0px 24px', maxWidth: 800 }}>
                    <Button type="primary" danger style={{ marginBottom: 10 }} onClick={() => onRefresh()}><SyncOutlined /> เริ่มใหม่</Button>
                    <Divider style={{ margin: '5px 0' }} />
                    {setLottoMasterByPermissions()}
                </Space>

                <Divider />
            </Spin>
        </MainLayout>
    )
}

export default Setting;

function IntegerStep(props: any) {
    const { defaultValue, onChange, disabled } = props;

    const [state, setState] = useState({
        inputValue: defaultValue,
    });

    const onChanges = (value: any) => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(value)) {
            return;
        }

        setState({
            ...state,
            inputValue: value,
        });
        if (onChange) onChange(value);
    };

    const { inputValue } = state;

    return (
        <Row>
            <Col span={12}>
                <Slider
                    min={0}
                    max={100}
                    disabled={disabled}
                    onChange={onChanges}
                    value={typeof inputValue === 'number' ? defaultValue : 0}
                />
            </Col>
            <Col span={4}>
                <InputNumber
                    min={0}
                    max={100}
                    disabled
                    style={{ margin: '0 16px' }}
                    value={defaultValue}
                    onChange={onChanges}
                />
            </Col>
        </Row>
    );
};